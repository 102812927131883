<template>
  <hb-dialog class="verify-check-dialog" size="sm">
    <hb-dialog-header> {{ $t('profile.check_your_email') }} </hb-dialog-header>
    <hb-dialog-body>
      <p>
        {{ $t('profile.verify_message', [userStore.user.email]) }}
      </p>

      <p>
        <strong>{{ $t('profile.did_not_receive_email') }}</strong>
        <hb-raw-btn
          class="verify-check-dialog__resend"
          :loading="pending"
          @click="resendEmail()"
        >
          <strong>{{ $t('profile.resend') }}</strong>
        </hb-raw-btn>
      </p>
    </hb-dialog-body>
    <hb-dialog-footer no-border>
      <hb-btn
        theme="secondary"
        size="block-lg"
        :disabled="pending"
        @click="dialog.close(afterClose)"
      >
        <span>{{ $t('label.ok') }}</span>
      </hb-btn>
    </hb-dialog-footer>
  </hb-dialog>
</template>

<script lang="ts">
import HbDialog from '~/components/base/dialog/HbDialog.vue'
import HbDialogFooter from '~/components/base/dialog/HbDialogFooter.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import HbDialogBody from '~/components/base/dialog/HbDialogBody.vue'
import HbDialogHeader from '~/components/base/dialog/HbDialogHeader.vue'
import { useApiHandler, useDialog, useDM, useToasts } from '~/composables'
import { DialogMixin } from '~/mixins'
import { useAuthStore, useUserStore } from '~/stores'
import HbRawBtn from '~/components/base/utils/HbRawBtn.vue'
import FailureDialog from '~/components/dialogs/FailureDialog.vue'
import { useI18n } from '#imports'

export default {
  name: 'VerifyCheckDialog',
  components: {
    HbRawBtn,
    HbDialogHeader,
    HbDialogBody,
    HbBtn,
    HbDialogFooter,
    HbDialog,
  },
  mixins: [DialogMixin],
  setup(props) {
    const { t } = useI18n()
    const dialog = useDialog(props)
    const userStore = useUserStore()
    const authStore = useAuthStore()
    const { execute, pending } = useApiHandler(() =>
      authStore.api.resendVerificationEmail()
    )
    const resendEmail = () => {
      execute(
        () => {
          useToasts().show({
            text: t('profile.resend_successful_message'),
          })
        },
        (error) => {
          useDM().open(FailureDialog, { message: error.message })
        }
      )
    }

    return {
      dialog,
      pending,
      userStore,
      resendEmail,
    }
  },
}
</script>

<style scoped lang="scss">
.verify-check-dialog {
  &__resend {
    text-decoration: underline;
    margin-left: 15px;
    color: var(--hb-secondary);
  }

  p {
    font-weight: 500;
    letter-spacing: -0.48px;
    line-height: 24px;
    font-size: 16px;

    & + p {
      margin-top: 40px;
    }
  }
}
</style>
