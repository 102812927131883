<template>
  <!-- <hb-btn v-bind="$props" @click="onClick()"> -->
  <hb-btn v-bind="$props">
      <nuxt-icon :name="icon"></nuxt-icon>
    <span>{{ $t('label.start_selling') }}</span>
  </hb-btn>
</template>

<script lang="ts">
import { BtnMixin } from 'wue'
import { navigateTo } from '#app'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import { useUserStore } from '~/stores'
import { useDM } from '~/composables'
import AuthDialog from '~/modules/auth/components/dialogs/AuthDialog.vue'
import ChangesComingDialog from '~/modules/auth/components/dialogs/ChangesComingDialog.vue'
import VerifyAccountDialog from '~/components/dialogs/VerifyAccountDialog.vue'
import { useFbq, useI18n } from '#imports'

export default {
  name: 'StartSellingBtn',
  components: { HbBtn },
  mixins: [BtnMixin],
  props: {
    icon: { type: String, default: 'loan' },
    isDisabled: {type: Boolean, default: false }
  },
  setup(props) {
    const { t } = useI18n()
    const userStore = useUserStore()
    const { open: openDialog } = useDM()
    const { track } = useFbq()
    const goToHomeUpload = () => {
      const navigateToUpload = () => {
        track('Lead', { content_name: 'Start Selling' })
        navigateTo({ path: '/home/upload' })
      }

      if (!userStore.user?.email_verified) {
        openDialog(VerifyAccountDialog, {
          message: t('verification.email_verification_start_selling_text'),
        })

        return
      }

      navigateToUpload()
    }
    const onClick = () => {
      if(!props.isDisabled) {
        if (!userStore.isAuthenticated()) {
          openDialog(AuthDialog, {
            afterClose(result: boolean) {
              if (result) {
                goToHomeUpload()
              }
            },
          })
          return
        }

        goToHomeUpload()
      } else {
        useDM().open(ChangesComingDialog, {
          verticalCenter: true,
          closeByEsc: true,
          closeByOverlay: true,
        })
      }
    }

    return {
      onClick,
    }
  },
}
</script>

<style scoped></style>
