<template>
  <hb-dialog size="sm">
    <hb-dialog-header :after-close="afterClose">
      {{ $t('profile.verify_account') }}
    </hb-dialog-header>
    <hb-dialog-body>
      <p>
        {{ message }}
      </p>
    </hb-dialog-body>
    <hb-dialog-footer no-border>
      <hb-btn-wrap vertical>
        <hb-btn
          theme="secondary"
          size="block-lg"
          :loading="pending"
          @click="resendEmail()"
        >
          <span>{{ $t('profile.send_verification') }}</span>
        </hb-btn>
        <hb-btn
          size="block-lg"
          theme="light"
          outline
          :disabled="pending"
          @click="dialog.close(afterClose)"
        >
          <span>{{ $t('label.cancel') }}</span>
        </hb-btn>
      </hb-btn-wrap>
    </hb-dialog-footer>
  </hb-dialog>
</template>

<script lang="ts">
import HbDialog from '~/components/base/dialog/HbDialog.vue'
import HbDialogFooter from '~/components/base/dialog/HbDialogFooter.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import HbDialogBody from '~/components/base/dialog/HbDialogBody.vue'
import HbDialogHeader from '~/components/base/dialog/HbDialogHeader.vue'
import { useApiHandler, useDialog, useDM } from '~/composables'
import { DialogMixin } from '~/mixins'
import { useAuthStore } from '~/stores'
import FailureDialog from '~/components/dialogs/FailureDialog.vue'
import HbBtnWrap from '~/components/base/utils/HbBtnWrap.vue'
import VerifyCheckDialog from '~/components/dialogs/VerifyCheckDialog.vue'

export default {
  name: 'VerifyAccountDialog',
  components: {
    HbBtnWrap,
    HbDialogHeader,
    HbDialogBody,
    HbBtn,
    HbDialogFooter,
    HbDialog,
  },
  mixins: [DialogMixin],
  props: {
    message: { type: String, required: true },
  },
  setup(props) {
    const dialog = useDialog(props)
    const authStore = useAuthStore()
    const { execute, pending } = useApiHandler(() =>
      authStore.api.resendVerificationEmail()
    )
    const resendEmail = () => {
      execute(
        () => {
          useDM().open(VerifyCheckDialog, {
            afterClose() {
              dialog.value.close(() => props.afterClose(true))
            },
          })
        },
        (error) => {
          useDM().open(FailureDialog, { message: error.message })
        }
      )
    }

    return {
      dialog,
      pending,
      resendEmail,
    }
  },
}
</script>

<style scoped lang="scss">
p {
  font-weight: 500;
  letter-spacing: -0.48px;
  line-height: 24px;
  font-size: 16px;

  & + p {
    margin-top: 40px;
  }
}
</style>
