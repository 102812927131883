<template>
  <hb-dialog size="md">
    <hb-dialog-header
      class="coming-soon-dialog__header-close"
    ></hb-dialog-header>
    <hb-dialog-body class="coming-soon-dialog__container">
      <h3
        class="coming-soon-dialog__header"
        v-html="$t('changes_coming.in_progress')"
      ></h3>
      <p class="coming-soon-dialog__content">{{ $t("changes_coming.desc") }}</p>
    </hb-dialog-body>
  </hb-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HbDialog from "~/components/base/dialog/HbDialog.vue";
import HbDialogHeader from "~/components/base/dialog/HbDialogHeader.vue";
import { useDialog } from "~/composables";
import { DialogMixin } from "~/mixins";
import HbDialogBody from "~~/src/components/base/dialog/HbDialogBody.vue";

export default defineComponent({
  name: "InProgressDialog",
  components: {
    HbDialogHeader,
    HbDialog,
    HbDialogBody,
  },
  mixins: [DialogMixin],
  props: {
    onSuccess: { type: Function, default: () => {} },
    closeOnSave: { type: Boolean, default: () => true },
  },
  setup(props, ctx) {
    const dialog = useDialog(props);
    return {
      dialog,
    };
  },
});
</script>

<style lang="scss">
.coming-soon-dialog {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-top: -50px;
  }

  &__header,
  &__content {
    text-align: center;
  }

  &__header {
    font-size: 26px;
    margin-bottom: 15px;
  }

  &__content {
    font-weight: 500;
    font-size: 16px;
    margin: 0 -10px 0 0;
  }

  &__header-close {
    margin: -15px -20px 0 0;
  }

  @include mobile {
    &__content {
      margin: 0;
    }

    &__container {
      margin: 10px 0 0 0;
    }
  }
}
</style>
